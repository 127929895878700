import { FullscreenAppContext } from '@wppopen/core'
import { AxiosResponse } from 'axios'
import _ from 'lodash'
import moment from 'moment'

import { getMarketsAPIPath } from 'config/apiPaths'
import { DATE_TIME_FORMAT } from 'config/constants'
import { CURRENCY_TYPES, FIELD_TYPES } from 'config/enums'
import IMarket from 'interfaces/common/IMarket'
import IOption from 'interfaces/common/IOption'
import IResponseField from 'interfaces/field/response/IResponseField'
import AxiosService from 'lib/AxiosService'
import IAppContextState from 'store/interfaces/IAppContextState'
import PermissionHelper from 'utils/permission/PermissionHelper'

export default class SharedHelper {
  /**
   * Get app state
   * @param {FullscreenAppContext} osContext
   * @returns {IAppContextState}
   */
  public static getAppState(osContext: FullscreenAppContext): IAppContextState {
    const baseUrl: string = osContext.baseUrl
    const appContext = baseUrl.split('/')
    let appInstanceId = 'b84d8cac-68f6-4f06-852e-1315b5e20cfe'

    if (_.isEqual(_.first(appContext), 'application')) {
      appInstanceId = appContext[1]
    }

    let permissions = PermissionHelper.getPermissions(osContext.permissions)
    let tenantLogo = osContext.tenant.logoThumbnail?.url

    const { tenant, project, userDetails } = osContext
    const isAppEditable = PermissionHelper.hasAppEditor(permissions)

    // For Local development
    let appConfiguration: IAppContextState = {
      tenantId: tenant.id,
      itemId: project ? project.itemId : '',
      projectId: project ? project.id : '',
      projectName: project ? project.name : '',
      userEmail: userDetails.email,
      homeUrl: tenant.homeUrl,
      appInstanceId: appInstanceId,
      loading: false,
      permissions,
      tenantLogo,
      isAppEditable,
    }

    if (_.isEqual(process.env.NODE_ENV, 'development') && _.isNull(project)) {
      appConfiguration = {
        tenantId: tenant.id,
        projectId: '11db4d3c-6426-4e40-8a41-50637da4b113',
        itemId: '299b7848-0ac1-423a-ba1a-87a03ec28fa9',
        projectName: 'Test_Project',
        userEmail: userDetails.email,
        homeUrl: tenant.homeUrl,
        appInstanceId: appInstanceId,
        loading: false,
        permissions,
        tenantLogo,
        isAppEditable,
      }
    }
    return appConfiguration
  }

  /**
   * If the value is not empty
   * @param {any} value
   * @returns {boolean}
   */
  isValidTextValue(value: any): boolean {
    return !_.isEmpty((value ?? '').toString().trim())
  }

  /**
   * If the value is not empty
   * @param {any} value
   * @returns {boolean}
   */
  isValidValue(value: any): boolean {
    if (_.isNumber(value)) {
      return true
    }
    return this.isValidTextValue(value)
  }

  /**
   * Difference of two array
   * @param {array} x
   * @param {array} y
   * @returns {boolean}
   */
  public static readonly isDiffArray = (x: any, y: any): any => {
    return _.xorWith(x, y, _.isEqual)
  }

  /**
   * Check and return valid array
   * @param {any[]} array
   * @returns {any[]}
   */
  getValidArray = (array: any): any[] => {
    return _.isArray(array) ? array : []
  }

  /**
   * Build Option Array
   * @param {any[]} array
   * @param {idKey} Option ID key
   * @param {labelKey} Option label key
   * @returns {IOption[]}
   */
  public static readonly getOptions = (
    array: any[],
    idKey: string,
    labelKey: string,
    parentIdKey: string = '',
  ): IOption[] => {
    return array.map((data: any) => ({
      id: data[idKey],
      label: data[labelKey],
      parentId: data[parentIdKey],
    }))
  }

  /**
   * Get Currency List
   * @returns {IOption[]}
   */
  getCurrencyList = (): IOption[] => {
    const CurrencyList: IOption[] = []
    for (let key in CURRENCY_TYPES) {
      const label: string = `${key} - ${CURRENCY_TYPES[key as keyof typeof CURRENCY_TYPES]}`
      CurrencyList.push({
        id: label,
        label,
      })
    }
    return CurrencyList
  }

  /**
   * Get label
   * @param {string[]} input
   * @param {any} inputResponse
   * @returns {string}
   */
  getLabel = (input: string[], inputResponse: any): string => {
    let label = ''

    input.forEach((data: string) => {
      label = _.isEmpty(label) ? inputResponse[data] : `${label} ${inputResponse[data]}`
    })

    return label
  }

  /**
   * Get select options
   * @param {IResponseField} field
   * @param {string} accessToken
   * @param {string} tenantId
   * @returns {Promise<IOption[]>}
   */
  getSelectOptions = async (field: IResponseField, accessToken: string, tenantId: string): Promise<IOption[]> => {
    const { options, apiUrl } = field
    if (!_.isEmpty(options)) return options
    const axiosService = new AxiosService(accessToken)

    if (_.isEqual(field.fieldType, FIELD_TYPES.CURRENCY)) {
      return this.getCurrencyList()
    }

    if (
      _.isEqual(field.fieldType, FIELD_TYPES.AUTOCOMPLETE) ||
      _.isEqual(field.fieldType, FIELD_TYPES.USER) ||
      _.isEqual(field.fieldType, FIELD_TYPES.USER_MENTION)
    ) {
      return options
    }

    if (_.isEqual(field.fieldType, FIELD_TYPES.MARKET)) {
      const response: AxiosResponse<{
        data: IMarket[]
      }> = await axiosService.get(getMarketsAPIPath(), tenantId)
      return response.data.data.map((market: IMarket) => ({ id: market.id, label: market.name }))
    }

    if (!accessToken || !apiUrl) return options ?? []

    if (!field?.config?.autocomplete) return []

    const { keys } = field?.config.autocomplete ?? {}
    const { id, label } = keys

    const Result: any = await axiosService.get(apiUrl, tenantId)
    const Response = _.isArray(Result.data.data) ? Result.data.data : []
    return Response.map((data: any) => {
      return {
        id: data[id],
        label: this.getLabel(label, data),
      }
    })
  }

  /**
   * Find array value inside the target value
   * @param {string[]} inputArray
   * @param {string[]} targetArray
   * @returns {boolean}
   */
  findAnyArrayValueInTargetArray = (inputArray: string[], targetArray: string[]): boolean => {
    for (let value of targetArray) {
      for (let inputValue of inputArray) {
        if (_.isEqual(_.toString(value), _.toString(inputValue))) {
          return true
        }
      }
    }
    return false
  }

  /**
   * Get formatted date in UTC
   * @param {string} date
   * @returns {string}
   */
  public static readonly getFormattedDateInUTC = (date: string): string => {
    if (!date) return ''
    return `${moment.utc(date).format(DATE_TIME_FORMAT)} (UTC)`
  }

  /**
   * Return base64 value for the image
   * @param {string} imgUrl
   * @param {Function} callback
   * @returns {void}
   */
  public static getBase64Image(imgUrl: string, callback: Function): void {
    let img = new Image()
    img.onload = function () {
      let canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      let ctx: any = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      let dataURL = canvas.toDataURL('image/png')
      dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
      callback(dataURL)
    }
    img.setAttribute('crossOrigin', 'anonymous') //
    img.src = imgUrl
  }
}
